.customers {
    height: -webkit-fill-available;
    margin: 40px;
    background-color: white;
    border: 2px solid #E4EDFF;
    border-radius: 10px;
}

.cell-nome{
    display: flex;
    gap: 10px;
    align-items: center;

    &__foto{
        height: 28px;
        width: 28px;
        border-radius: 50px;
        border: 2px solid #1C3FB7;
    }
}

.cell-agenda{
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: #EDF5ED;
    border-radius: 20px;
    padding-block: 4px;
    padding-inline: 16px;
    width: max-content;

    &__icon {
        height: 16px;
        width: 16px;
        color: #06A09B;
    }

    &__texto {
        font-size: 16px;
        font-weight: 500;
        color: #06A09B;
    }
}