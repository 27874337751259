.calendar {
    &__grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 17px;
    }

    &__title {
        color: #09005D;
        font-size: 28px;
        font-weight: 600;
    }
}

.calendar__grid-item {
    text-align: right;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    height: 124px;
    width: 128px;
    color: #D8DEE8;
    font-weight: 600;

    &-header {
        font-size: 18px;
        margin: 0px;
        display: flex;
        justify-content: space-between;

        &-services {
            display: grid;
            grid-template-columns: repeat(5, 6px);
            gap: 3px;

            &-balls {
                width: 6px;
                height: 6px;
                border-radius: 50px;
            }
        }

        &-dayOfWeek {
            font-size: 18px;
            margin: 0px;
        }
    }

    &-day {
        font-size: 40px;
        line-height: 40px;
        margin: 0px;
    }

    &-customers {
        display: flex;
        font-size: 12px;
        gap: 4px;
        padding-block: 4px;
        padding-inline: 8px;
        margin-top: 8px;
        background-color: #F3F5F9;
        border-radius: 20px;
        justify-content: center;
        box-shadow: 2px 2px 3px 0px #ececec;
    }
}

.calendar__grid-item.filled:hover {
    cursor: pointer;
    background-color: #E1E8FF;

    &.future {
        color: #1C3FB7;
    }
}

.calendar__grid-item-customers svg {
    height: 16px;
    width: 16px;
}

.calendar__grid-item.empty {
    background-color: transparent;
}

.calendar__grid-item.filled {
    background-color: white;
    box-shadow: 2px 2px 10px 0px #ececec;

    &.today {
        border: 1px solid #8099EC;
        color: #1C3FB7;
    }

    &.future {
        border: 1px solid #8099EC;
        color: #C3CEF6;
    }
}