.input-text {
    border: 2px solid #ADBCF2;
    border-radius: 10px;
    padding: 11px 20px;
    font-weight: 200;
    font-size: 16px;
    color: #9CA3AF;
    -webkit-transition: 0.5s;
    transition: 0.5s linear;
    width: 100%;
    box-sizing: border-box;
}

.input-text::placeholder {
    color: #9CA3AF;
    font-size: 16px;
    font-weight: 200;
}

.input-text:focus {
    border: 2px solid #3758F9;
    border-radius: 10px;
    outline: none;
}

.input-text.error {
    border: 2px solid rgb(255, 87, 87);
}
.input-text.error:focus {
    border: 2px solid rgb(255, 87, 100);
    border-radius: 10px;
    outline: none;
}

.input-label-error {
    font-weight: 200;
    font-size: 14px;
    color: rgb(255, 87, 87);
    margin: 0px;
}

.input-text:focus {
    border: 2px solid #3758F9;
    border-radius: 10px;
    outline: none;
}

.primary-button {
    background-color: #3758F9;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding-block: 13px;
    outline: none;
    border: 0px;
    border-radius: 50px;
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.primary-button:hover {
    background-color: #1C3FB7;
    cursor: pointer;
}

.primary-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.secundary-button {
    background-color: white;
    color: #1C3FB7;
    font-size: 18px;
    font-weight: 600;
    padding-block: 12px;
    padding-inline: 20px;
    outline: none;
    border: 0px;
    border-radius: 10px;
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    box-shadow: 2px 2px 10px 0px #ececec;
}

.secundary-button:hover {
    background-color: #1C3FB7;
    color: white;
    cursor: pointer;
}

.terciary-button {
    background-color: #1C3FB7;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding-block: 13px;
    outline: none;
    border: 0px;
    border-radius: 10px;
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    &__text {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
    }
}

.terciary-button:hover {
    background-color: #3758F9;
    cursor: pointer;
}