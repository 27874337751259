.card-dash {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px;
    padding: 40px 20px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 0px #ececec;
    transition: border 0.1s linear;
    height: min-content;

    p {
        font-weight: 600;
        font-size: 18px;
        margin: 0px;
    }

    .card-dash__list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &-item {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 10px 0;
            border-bottom: 1px solid #E4EDFF;

            &:last-child {
                border-bottom: none;
            }

            .icone {
                color: white;
                height: 31px;
                width: 38px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .hora {
                font-size: 18px;
                font-weight: 600;
            }

            .nome {
                font-size: 18px;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }

    &.initial {
        border: 1px solid #8099EC;

        .card-dash__icon {
            color: #8099EC;
        }

        .card-dash__description {
            color: #1C3FB7;
        }
    }

    &.empty {
        border: 1px solid #C76363;

        .card-dash__icon {
            color: #C76363;
        }

        .card-dash__description {
            color: #C76363;
        }
    }

    &.today {
        border: 1px solid #8099EC;

        .card-dash__icon {
            color: #8099EC;
        }

        .card-dash__description {
            color: #1C3FB7;
        }

        .card-dash__list {
            &-item {
                .icone {
                    background-color: #8099EC;
                }
            }
        }

    }

    &.available {
        border: 1px solid #72DDC3;

        .card-dash__icon {
            color: #06A09B;
        }

        .card-dash__description {
            color: #06A09B;
        }

        .card-dash__list {
            &-item {

                .icone {
                    background-color: #72DDC3;
                }
            }
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 5px #ececec;
    }
}