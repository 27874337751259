.description-field {
    position: relative;

    .input-text {
        border: 2px solid #ADBCF2;
        border-radius: 10px;
        font-weight: 200;
        font-size: 16px;
        color: #9CA3AF;
        -webkit-transition: 0.5s;
        transition: 0.5s linear;
        padding-left: 150px;
        box-sizing: border-box;

    }

    .input-text::placeholder {
        color: #9CA3AF;
        font-size: 16px;
        font-weight: 200;
    }

    .input-text:focus {
        border: 2px solid #3758F9;
        border-radius: 10px;
        outline: none;
    }

    &__add-image {
        position: absolute;
        left: 24px;
        top: 24px;
        height: 103px;
        width: 103px;
        border: 0;
        background: white;
        color: #8099EC;
        outline: none;
        border-radius: 10px;
        z-index: 2;
        box-shadow: 2px 2px 10px 0px #ececec;
    }

    &__add-image svg {
        width: 63px;
        height: 63px;
        fill: currentColor;
    }
}