// .custom_table {
//     background-color: white;
//     border: 2px solid #E4EDFF;
//     border-radius: 10px;
// }

.custom_table__table {
    border-collapse: collapse;
    border: none;
    width: 100%;
}

.custom_table__table td {
    border: none;
    border-left: 2px solid #E4EDFF;
    border-bottom: 2px solid #E4EDFF;
    padding: 8px;
}

.custom_table__table td:first-child {
    border: none;
    border-bottom: 2px solid #E4EDFF;
}

.custom_table__table th:first-child {
    border-left: none;
}

.custom_table__table th {
    border: none;
    border-bottom: 2px solid #E4EDFF;
    border-left: 2px solid #E4EDFF;
    padding: 8px;
}

.custom_table__table tr:nth-child(even) {
    background-color: #F4F8FF;
}

.custom_table__table tr:hover td {
    background-color: #E4EDFF !important;
    cursor: pointer;
}

.custom_table__table tr:nth-child(odd) td {
    background-color: #FBFCFF;
}

.custom_table__table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: #353535;
    font-size: 18px;
    font-weight: 600;
}