.app-layout__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
}

.app-layout__outlet {
    width: 1064px;
    height: 826px;
    max-width: 100%;
    max-height: 100%;
    background-color: #FAFCFF;
    border-radius: 20px;
    box-sizing: border-box;
    margin-top: 70px;
}