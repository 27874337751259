.container {
    background-color: white;
    max-width: 1440px;
    max-height: 1024px;
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-container {
    display: flex;
}

.side {
    flex: 1;
    box-sizing: border-box;
}

.right-side {
    border-left: 2px solid #c3cef680;
    margin-top: 60px;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-inline: 100px;
    padding-block: 40px;
}

.login-logo {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 96px;
    color: #5475E5;
    margin: 0px;
    margin-left: 66px;
}

.login-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 48px;
    color: #5475E5;
    margin: 0px;
}

.login-img {
    position: absolute;
    width: 57%;
}

.login-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-label {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding-bottom: 10px;
}

.login-sub-label {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding-top: 10px;
    color: #555555;
}

.login-forgot-pass a {
    color: #555555;
    font-size: 16px;
}

.password-input-container {
    position: relative;
}

.password-input-container .MuiIconButton-root {
    position: absolute;
    top: 35px;
    right: 15px;
}

.login-buttons {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #8099ec77;
}

.separator:not(:empty)::before {
    margin-right: 20px;
}

.separator:not(:empty)::after {
    margin-left: 20px;
}

.login-confirm-fields {
    display: flex;
    gap: 38px;
    margin-block: 20px;
    justify-content: space-evenly;
}

.login-register {
    font-weight: 600;
    text-align: center;
    margin-top: 16px;
}

.login-subtitle {
    font-weight: 600;
}