.messages {
    height: -webkit-fill-available;
    margin: 40px;
    background-color: white;
    border: 2px solid #E4EDFF;
    border-radius: 10px;

    &__table {
        border-collapse: collapse;
        width: 100%;

        td,
        th {
            border: 1px solid #E4EDFF;
            padding: 8px;
        }

        th {
            background-color: white;
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: #353535;
        }

        tr {
            background-color: #FBFCFF;
        }
    }

    &__create-button {
        position: absolute;
        bottom: 5%;
        right: 3%;
        width: 310px;
    }
}

.td_name {
    display: flex;
    gap: 10px;
    align-items: center;

    &_icon {
        color: #06A09B;
    }
}

.tag {
    font-weight: 700;
    padding: 4px 8px;
    border-radius: 20px;

    &.new {
        color: #1C3FB7;
        background-color: #E4EDFF;
    }

    &.cancel {
        color: #C76363;
        background-color: #f0dcdc;
    }
}