.user-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__apresentation {

        &_name {
            font-size: 28px;
            font-weight: 600;
            line-height: 40px;
            margin: 0px;
            color: #111928;
        }

        &_data {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            margin: 0px;
            color: #353535;
        }
    }

    &__profile {
        display: flex;
        gap: 24px;

        &_avatar {
            height: 60px;
            width: 60px;
            border-radius: 12px;
            overflow: hidden;

            &-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &_user {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            &_name {
                font-size: 16px;
                font-weight: 600;
                color: #1F2A37;
                line-height: 24px;
                margin: 0px;
            }

            &_role {
                font-size: 16px;
                font-weight: 400;
                color: #555555;
                line-height: 24px;
                margin: 0px;
            }
        }

    }
}