.profile {
    margin: 40px;

    &__title {
        font-size: 20px;
        font-weight: 600;
        color: #1C3FB7;
    }

    &__description {
        padding-top: 30px;
        padding-bottom: 36px;
    }

    &__form {

        &-address {
            background-color: #F3F5F9;
            border-radius: 20px;
            padding: 20px;
        }
    }

    &__field {
        display: flex;
        align-items: center;

        &_icone {
            color: #353535;
        }

        &_label {
            white-space: nowrap;
            color: black;
            font-size: 18px;
            font-weight: 600;
            padding-left: 8px;
            margin: 0px;
        }

        &_input {
            display: flex;
            gap: 36px;
        }

        .search-button {
            width: 127px;
            height: 48px;
            margin-left: 36px;
        }
    }
}

.row {
    margin-bottom: 36px;
    align-items: center;
}

.row.row-submit {
    padding-top: 36px;
    padding-bottom: 36px;
}