.icon-button {
    background-color: white;
    color: #555555;
    font-size: 16px;
    font-weight: 500;
    padding-block: 13px;
    outline: none;
    border: 1px solid black;
    border-radius: 50px;
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: flex;
    align-items: center;
    padding-inline: 20%;
    gap: 10px;

    &__text {
        flex: 1;
        text-align: center;
    }

    &__icon {
        display: flex;
        align-items: end;
        justify-content: center;
    }
}

.icon-button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 10px #ececec;
}