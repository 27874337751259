.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 320px;
    background-color: #FAFCFF;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 1px 10px 10px 4px #ececec;
    overflow: auto;

    a {
        text-decoration: none;
    }

    &__logo {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        color: #5475E5;
        margin: 34px 28px;
    }

    &__menu {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__section {
            padding: 0px 16px;
            margin-bottom: 30px;
            margin-top: 10px;
            font-size: 14px;
            font-weight: 600;
            color: #555555;
        }

        &__item {
            display: flex;
            height: 52px;
            align-items: center;
            padding: 0px 16px;
            margin-inline: 20px;
            font-size: 18px;
            font-weight: 500;
            color: #353535;
            transition: color 0.3s ease-in-out;

            &.active {
                color: #1C3FB7;
                font-weight: 600;

                .sidebar__menu__item__icon svg {
                    color: #8099EC;
                }
            }

            &__icon {
                margin-right: 12px;
                height: 36px;
                width: 36px;
            }

            .sidebar__menu__item__icon svg {
                width: 100%;
                height: 100%;
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 20px;
            background-color: #FFFFFF;
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
            box-shadow: 0px 0px 10px 4px #ececec;
        }
    }
}